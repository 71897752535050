import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import enUS from 'date-fns/locale/en-US';
import parseISO from 'date-fns/parseISO';
import sub from 'date-fns/sub';
import * as dateFnsTz from 'date-fns-tz';
import isEmpty from 'lodash/isEmpty';

import {
  OwnerPaymentsTemplate,
  OwnerPayrollTemplate,
  ResultMessageType,
  TemplateKey,
  UnverifiedTemplate,
} from 'types/app';
import { ConfigurationQuestion, ConfigurationType } from 'types/equipmentItem';
import { Events } from 'types/events';
import { externalRoutes } from 'types/pages';
import { BatchSummary, DefaultDateFormat, GroupedBatches } from 'types/reports';
import { SGACustomerStatusResult, SGAPropertyList } from 'types/sga';
import { ActionType, SupportItem } from 'types/supportItem';
import { CustomerId, GranularPermissions, User, UserInterface } from 'types/user';
import tracker from 'utility/eventTracking';

import { defaultTimeZone, supportedTimeZones } from './supportedTimeZones';

export function dateOutOfRange(max: Date, min: Date, date?: Date) {
  if (date == null) return true;
  return isBefore(date, min) || isAfter(date, max);
}

export const arrayDifference = (arr1: Array<any>, arr2: Array<any>) => {
  const diff1 = arr1.filter((x) => !arr2.includes(x));
  const diff2 = arr2.filter((x) => !arr1.includes(x));
  return [...diff1, ...diff2];
};

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? (Math.sign(num) * Math.round(Math.abs(num) / 100)) / 10 + 'K'
    : Math.sign(num) * Math.abs(num);
};

export const cleanUTCDate = (date: string) => {
  let newDate = date;
  if (newDate.charAt(5) === '0') {
    newDate = newDate.slice(0, 5) + newDate.slice(6);
  }
  if (newDate.indexOf('T') !== -1) newDate = newDate.slice(0, newDate.indexOf('T'));
  return newDate.replace(/-/g, '/');
};

export const createSelector = (name: string) => {
  return name
    .replace(/[^a-zA-Z0-9\s]+/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();
};

export const numberWithCommas = (value: number) => {
  return Number(value)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberWithCommasRelevantDecimal = (value: number) => {
  //verify if number has relevant decimal values
  if (countDecimals(value) > 2) {
    //if it does, return the number with 2 decimal values
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return parseInt(value.toString());
  }
};

export const countDecimals = (value: any) => {
  // eqeq required for decimal validation
  // eslint-disable-next-line
  if (Math.floor(value) == value) return 0;
  return value.toString().split('.')[1].length || 0;
};

export const stripPhoneMask = (phoneStr: string) => {
  const maskSymbol = ['(', ')', '-', ' '];

  maskSymbol.forEach((symbol) => {
    phoneStr = phoneStr.replace(symbol, '');
  });

  return phoneStr;
};

export const maskPhone = (phoneStr: string) => {
  // Remove any non-digit characters from the input
  const onlyDigitsPhone = phoneStr.replace(/\D/g, '');

  // Check if the cleaned number has 10 digits (without country code)
  if (onlyDigitsPhone.length === 10) {
    // Format the phone number using Regex
    const formatted = onlyDigitsPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return formatted;
  } else {
    // If the number does not have 10 digits, return it as is
    return phoneStr;
  }
};

export function getQueryParams(query: string) {
  const params = new URLSearchParams(query);
  const QueryStringParameters = {};

  params.forEach((v: any, k: any) => {
    Object.assign(QueryStringParameters, { [k]: v });
  });

  return QueryStringParameters;
}

export const getInitials = ({ name, firstName, lastName }: UserInterface) => {
  let initials = firstName.charAt(0) + lastName.charAt(0);
  try {
    if (isEmpty(initials)) {
      initials = name.charAt(0) + name.split(' ').pop()?.charAt(0);
    }
  } catch (err) {
    console.log(err);
    initials = '??';
  }
  return initials;
};

export const truncateZip = (zip: string) => {
  if (zip.length > 5) {
    return zip.substring(0, 5);
  }
  return zip;
};

export function isVerified(activeCustomerId: CustomerId | null): boolean {
  return !!activeCustomerId && !isEmpty(activeCustomerId?.idType) && !isEmpty(activeCustomerId?.idValue);
}

export function openChatBot() {
  const openChatBotScript = document.createElement('script');
  openChatBotScript.innerHTML = "zE('messenger', 'open');";
  document.body.appendChild(openChatBotScript);
  document.body.removeChild(openChatBotScript);
}

export const BuildExternalRoute = (
  route: string,
  email: string = '',
  id: string = '',
  impersonateMode: boolean = false,
) => {
  let encodedEmail = encodeURIComponent(email);

  let mid = id;
  if (isEmpty(mid) || mid == null) {
    mid = 'notFound';
  }

  switch (route) {
    case externalRoutes.paymentsDashboard:
      if (impersonateMode) return '/paymentsPlus?customerUnauthorized=true';
      return `${process.env.NEXT_PUBLIC_PAYMENTS_PLUS_URL}/api/heartland/GetPortalUrl?email=${encodedEmail}&mid=${mid}&path=%2fportal%2fdashboard`;
    case externalRoutes.payrollPlus:
      return process.env.NEXT_PUBLIC_PAYROLL_PLUS_URL ?? '';
    case externalRoutes.payrollHiringAndBoarding:
      return `${process.env.NEXT_PUBLIC_PAYROLL_PLUS_HCM_URL}/hiring`;
    case externalRoutes.payrollTimeAndAttendance:
      return `${process.env.NEXT_PUBLIC_PAYROLL_PLUS_HCM_URL}/time`;
    case externalRoutes.payrollBenefitsAdministration:
      return `${process.env.NEXT_PUBLIC_PAYROLL_PLUS_HCM_URL}/benefits`;
    case externalRoutes.infoCentral:
      return `${process.env.NEXT_PUBLIC_HIC_URL}/#signin`;
    case externalRoutes.infoCentralBatchesReports:
      return `${process.env.NEXT_PUBLIC_HIC_URL}/#${mid}/merchantbatches`;
    case externalRoutes.infoCentralTransactionReports:
      return `${process.env.NEXT_PUBLIC_HIC_URL}/#${mid}/merchanttransactions`;
    case externalRoutes.infoCentralFundingReports:
      return `${process.env.NEXT_PUBLIC_HIC_URL}/#${mid}/merchantfunding`;
    case externalRoutes.infoCentralStatementsReports:
      return `${process.env.NEXT_PUBLIC_HIC_URL}/#${mid}/merchantstatements`;
    case externalRoutes.billPayMerchantView:
      return process.env.NEXT_PUBLIC_BILLPAY_URL ?? '';
    default:
      return '';
  }
};

export const getSupportLinkHref = (supportItem: SupportItem) => {
  switch (supportItem.actionType) {
    case ActionType.email:
      return `mailto:${supportItem.email}`;
    case ActionType.phone:
      return `tel:${supportItem.phoneNumber}`;
    case ActionType.url:
      return supportItem.actionUrl!;
    default:
      return undefined;
  }
};

//write a function that turns a string to have a capital first letter and the rest lowercase
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatURL = (originalURL: string) => {
  const regex = /^(?!https?:\/\/|\/\/)/;
  const formattedURL = originalURL.replace(regex, 'https://');
  return formattedURL;
};

export const getSupportLinkClickEvent = (supportItem: SupportItem, appInsights: any) => {
  const trackZenDesk = (actionUrl: string) => {
    tracker.trackEvent(Events.ZENDESK, { URL: actionUrl }, appInsights);
  };

  switch (supportItem.actionType) {
    case ActionType.chat:
      return helperFunctions.openChatBot;
    case ActionType.url:
      return () => {
        trackZenDesk(supportItem.actionUrl!);
      };
    default:
      return undefined;
  }
};

// Extract product name from the subscriptionName
export const extractProductName = (text: string) => {
  const stringRegex = /^(.*?)\s*\(/;
  const stringMatch = text.match(stringRegex);
  const extractedString = stringMatch ? stringMatch[1].trim() : null;
  return extractedString;
};

// Extract product quantity from the subscriptionName
export const extractProductQuantity = (text: string) => {
  // Extracting the number of rolls and the word after
  const rollRegex = /(\d+)\s*(\w+)\s*/i;
  const rollsMatch = text.match(rollRegex);
  const numberOfRolls = rollsMatch ? rollsMatch[1] : '';
  const wordAfter = rollsMatch ? rollsMatch[2] : '';
  return numberOfRolls + ' ' + wordAfter.charAt(0).toUpperCase() + wordAfter.slice(1)!; // Output: 10
};

// Extract product recurrence period from the subscriptionName
export const extractProductRecurrence = (text: string) => {
  const regex = /(\d+)\s+(\w+)/;
  const match = text.match(regex);

  if (match) {
    const numberOfDays = match[1];
    const word = match[2];
    return numberOfDays + ' ' + word;
  } else {
    return '';
  }
};

export const extractSubscriptionDays = (text: string) => {
  const regex = /(\d+)\s+\w+/;
  const match = text.match(regex);

  if (match) {
    const numberOfDays = match[1];
    return numberOfDays;
  } else {
    return '';
  }
};

//create a function that transforms date format yyyy-mm-dd to mm/dd/yyyy
export const formatDateToDay = (date: string) => {
  const dateString = date.substring(0, 10);
  const dateArray = dateString.split('-');
  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
};

export const convertStringToDate = (strValue: string, format?: string) => {
  const formatChosen = !isEmpty(format) ? format! : 'yyyy-MM-dd';
  switch (strValue) {
    case 'today':
      return dateFnsTz.format(new Date(), formatChosen, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    default:
      if (isValid(new Date(strValue))) {
        try {
          return dateFnsTz.format(parseISO(strValue), formatChosen, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        } catch {
          const date = new Date(strValue);
          return dateFnsTz.format(date, formatChosen, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        }
      }

      return '';
  }
};

export const getEquipmentQuestionDefaultValue = (
  question: ConfigurationQuestion,
  value: string | null,
  adminEmail: string,
) => {
  let result: string = '';
  switch (question.fieldType) {
    //Make this configuration type the administrator type
    case ConfigurationType.AdministrationEmail:
      result = adminEmail;
      break;
    case ConfigurationType.AutoClose:
      if (value != null) {
        result = value;
      } else {
        const fieldDefinitionJson = JSON.parse(question.fieldDefinition);
        let time = fieldDefinitionJson?.defaultTime ?? '04:00';

        const sampleDate = new Date();
        let tzGuess = dateFnsTz.format(sampleDate, 'z', {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          locale: enUS,
        });

        if (
          supportedTimeZones.some((tz) => tzGuess === dateFnsTz.format(sampleDate, 'z', { timeZone: tz, locale: enUS }))
        )
          result = `${time} ${tzGuess}`;
        else result = `${time} ${dateFnsTz.format(sampleDate, 'z', { timeZone: defaultTimeZone, locale: enUS })}`;
      }
      break;
    default:
      break;
  }
  return result;
};

export const toCamelCase = (key: string, value: any) => {
  if (value && typeof value === 'object') {
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
};

export const removeLeadingZeroes = (value: string) => {
  return value.replace(/^0+/, '');
};

export const scrollToElement = (e: Element, additionalOffset: number = 0, behavior: string = 'smooth') => {
  let header = document.getElementsByTagName('header')[0];
  let banner = document.getElementById('banner-alert');

  const yOffset = -1 * ((header ? header.offsetHeight : 0) + (banner ? banner.offsetHeight : 0) + additionalOffset);
  const y = e.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: behavior as ScrollBehavior });
};

export const AddModifySgaProperties = (newProperties: SGAPropertyList, existing: SGAPropertyList) => {
  let returnList: SGAPropertyList = [];
  newProperties.forEach((n) => {
    let exists = existing.find((p) => p.key == n.key);

    if (exists && n.value.value == '') {
      existing = existing.filter((e) => e.key != n.key);
      return;
    }

    if (exists) {
      returnList.push({ key: n.key, value: { value: n.value.value, sensitive: n.value.sensitive } });
      existing = existing.filter((e) => e.key != n.key);
    } else {
      returnList.push(n);
    }
  });

  return [...returnList, ...existing];
};

export const GetSgaPropertyValue = (propertyKey: string, properties: SGAPropertyList) => {
  let result = '';

  let exists = properties.find((p) => p.key === propertyKey);

  if (exists && !exists.value.sensitive) {
    return exists.value.value;
  }

  return result;
};

export const transformSGAProperties = (properties: SGAPropertyList) => {
  return properties.map((p) => {
    return {
      key: p.key,
      value:
        typeof p.value === 'string' ? p.value : JSON.stringify({ Value: p.value.value, Sensitive: p.value.sensitive }),
    };
  });
};

export const transformSGAPropertiesToDictionary = (properties: SGAPropertyList) => {
  var props = transformSGAProperties(properties);
  var dictionary: { [key: string]: string } = {};

  props.forEach((prop) => {
    dictionary[prop.key] = prop.value;
  });

  return dictionary;
};

export const transformSGAPropertiesFromDictionary = (properties: { [key: string]: any }) => {
  const result = Object.entries(properties).map(([key, value]) => ({
    key,
    value: { value: value.value, sensitive: value.sensitive },
  }));

  return result;
};

export const SaveTemplateToLocalStorage = (json: User) => {
  let templates = localStorage.getItem(TemplateKey);

  const uniqueName = json.activeCustomerInformation.dbaName;
  if (
    uniqueName === OwnerPaymentsTemplate ||
    uniqueName === OwnerPayrollTemplate ||
    uniqueName === UnverifiedTemplate
  ) {
    alert(
      `Cannot save template because the dbaName: '${uniqueName}' is reserved. Please change the dbaName to save a custom template.`,
    );
    return;
  }

  if (templates == null) {
    localStorage.setItem(TemplateKey, JSON.stringify([json]));
  }

  if (templates != null) {
    const addingItem: User = json;
    const existingTemplates: Array<User> = JSON.parse(templates);

    const match = existingTemplates.findIndex(
      (o) => o.activeCustomerInformation.dbaName == addingItem.activeCustomerInformation.dbaName,
    );

    if (match !== -1) {
      existingTemplates[match] = addingItem;
      localStorage.setItem(TemplateKey, JSON.stringify([...existingTemplates]));
      return;
    }

    localStorage.setItem(TemplateKey, JSON.stringify([...existingTemplates, addingItem]));
  }
};

export const RemoveTemplateFromLocalStorage = (json: User) => {
  let templates = localStorage.getItem(TemplateKey);

  if (templates != null) {
    const existingTemplates: Array<User> = JSON.parse(templates);

    const match = existingTemplates.findIndex(
      (o) => o.activeCustomerInformation.dbaName == json.activeCustomerInformation.dbaName,
    );

    if (match !== -1) {
      existingTemplates.splice(match, 1);
      localStorage.setItem(TemplateKey, JSON.stringify([...existingTemplates]));
    }
  }
};

export const createOpenBatchSummaryCard = (newData: Array<BatchSummary>) => {
  const batches = newData;

  // Sort batches by batchCreateDate
  const sortedBatches = batches
    .slice()
    .sort((a, b) => new Date(b.batchCreateDate).getTime() - new Date(a.batchCreateDate).getTime());

  const GroupedBatches: GroupedBatches = {
    totalAmount: 0,
    totalBatches: 0,
    totalTransactions: 0,
    batches: [],
  };

  let totalAmount = 0;
  let totalTransactions = 0;

  sortedBatches.forEach((batch) => {
    totalAmount += batch.netAmount;
    totalTransactions += batch.transactionCount;
    GroupedBatches.batches.push({
      batchClosedDate: batch.batchClosedDate,
      batchCreateDate: batch.batchCreateDate,
      batchId: batch.batchId,
      merchantNumber: batch.merchantNumber,
      terminalDescription: batch.terminalDescription,
      totalRecordCount: batch.totalRecordCount,
      batchNumber: batch.batchNumber,
      batchStatus: batch.batchStatus,
      netAmount: batch.netAmount,
      transactionCount: batch.transactionCount,
      terminalNumber: batch.terminalNumber,
    });
  });

  GroupedBatches.totalBatches = sortedBatches.length;
  GroupedBatches.totalAmount = totalAmount;
  GroupedBatches.totalTransactions = totalTransactions;

  return GroupedBatches;
};

export const groupClosedBatchesByDateWithTotals = (newData: Array<BatchSummary>) => {
  const batches = newData;

  // Sort batches by batchCreateDate
  const sortedBatches = batches
    .slice()
    .sort((a, b) => new Date(b.batchClosedDate).getTime() - new Date(a.batchClosedDate).getTime());

  // Merge existing batches with new batches
  const mergedBatches: Record<string, GroupedBatches> = {};

  sortedBatches.forEach((batch) => {
    const dateKey = dateFnsTz.formatInTimeZone(parseISO(batch.batchClosedDate), 'UTC', DefaultDateFormat);
    mergedBatches[dateKey] = mergedBatches[dateKey] || {
      totalAmount: 0,
      totalBatches: 0,
      totalTransactions: 0,
      batches: [],
    };

    // Check if batch is not already present for this date
    const isNewBatch = !mergedBatches[dateKey].batches.some((existingBatch) => existingBatch.batchId === batch.batchId);

    if (isNewBatch) {
      // Update totals
      mergedBatches[dateKey].totalAmount += batch.netAmount;
      mergedBatches[dateKey].totalTransactions += batch.transactionCount;

      // Check if batch number is not already present for this date
      const isNewBatchNumber = !mergedBatches[dateKey].batches.some(
        (existingBatch) => existingBatch.batchNumber === batch.batchNumber,
      );

      if (isNewBatchNumber) {
        // Update total batch count
        mergedBatches[dateKey].totalBatches += 1;
      }

      // Add batch data to array
      mergedBatches[dateKey].batches.push({
        batchCreateDate: batch.batchCreateDate,
        batchClosedDate: batch.batchClosedDate,
        batchId: batch.batchId,
        merchantNumber: batch.merchantNumber,
        terminalDescription: batch.terminalDescription,
        totalRecordCount: batch.totalRecordCount,
        batchNumber: batch.batchNumber,
        batchStatus: batch.batchStatus,
        netAmount: batch.netAmount,
        transactionCount: batch.transactionCount,
        terminalNumber: batch.terminalNumber,
      });
    }
  });

  return mergedBatches;
};

export const shouldStartShoppingCartSGA = (data: SGACustomerStatusResult) => {
  if (data.completed && data.userHasViewPermission) {
    return true;
  }

  if (data.userInProgress && data.userHasStartPermission) {
    return true;
  }

  if (!data.userHasStartPermission && data.userHasViewPermission && data.anyInProgress) {
    return true;
  }

  return false;
};

export const shouldStartPaymentsPlusSGA = (data: SGACustomerStatusResult) => {
  if (data.completed && data.userHasViewPermission) {
    return true;
  }

  if (data.userInProgress && data.userHasStartPermission) {
    return true;
  }

  return false;
};

export const vegaNotifyToast = (message: string, type: ResultMessageType, duration: number) => {
  //@ts-ignore
  window.VegaNotify.open({
    duration: duration,
    type: type,
    message: message,
  });
};

export function maxReportDate() {
  return sub(new Date(), { days: 1 });
}

export function minReportDate() {
  return new Date(2020, 4, 1, 0, 0, 0, 0);
}

export function formattedMaxReportDate() {
  return format(maxReportDate(), DefaultDateFormat);
}

export function downloadDocumentLink(blob: any, downloadDocumentFileName: any) {
  var link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = downloadDocumentFileName;
  link.click();
}

export const verifyGranularPermissions = (userContext: User, requiredPermissions: Array<GranularPermissions>) => {
  const userPermissionSet = new Set(userContext.permissionSet.permissions);
  return requiredPermissions.every((permission) => userPermissionSet.has(permission));
};

export const isValidQueryString = (queryString: string) => {
  // Decode any URL-encoded characters
  try {
    queryString = decodeURIComponent(queryString);
  } catch (error) {
    // If decoding fails, it means the input is malformed
    return false;
  }

  // Validation regex
  // Allow alphanumerics, spaces, slashes, hyphens, and restrict special characters
  const regex = /^[a-zA-Z0-9-\/ ]+$/;

  const forbiddenPatterns = [
    /\.\.\//, // Prevent directory traversal
    /<script>/i, // Prevent script tags
    /[\x00-\x1F\x7F]/, // Prevent control characters
  ];

  // Return false if any forbidden patterns are found
  for (const pattern of forbiddenPatterns) {
    if (pattern.test(queryString)) {
      return false;
    }
  }

  // Validate the query string against the regex
  return regex.test(queryString);
};

//We export this way to mock the functions in jest.
const helperFunctions = {
  arrayDifference,
  kFormatter,
  cleanUTCDate,
  dateOutOfRange,
  createSelector,
  vegaNotifyToast,
  numberWithCommas,
  countDecimals,
  stripPhoneMask,
  getQueryParams,
  downloadDocumentLink,
  isVerified,
  openChatBot,
  BuildExternalRoute,
  getSupportLinkHref,
  getSupportLinkClickEvent,
  extractProductName,
  extractProductQuantity,
  extractProductRecurrence,
  shouldStartShoppingCartSGA,
  shouldStartPaymentsPlusSGA,
  extractSubscriptionDays,
  convertStringToDate,
  getEquipmentQuestionDefaultValue,
  toCamelCase,
  scrollToElement,
  createOpenBatchSummaryCard,
  capitalizeFirstLetter,
  groupClosedBatchesByDateWithTotals,
  transformSGAProperties,
  transformSGAPropertiesToDictionary,
  transformSGAPropertiesFromDictionary,
  verifyGranularPermissions,
  isValidQueryString,
};

export default helperFunctions;
